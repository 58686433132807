import React from 'react';

import { Layout } from '../components/layout';
import SEO from '../components/seo';
import { Container } from '../components/Container';

export default function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p>
      </Container>
    </Layout>
  );
}
